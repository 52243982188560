.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;  
  margin-right: auto;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: auto;
  padding-right: auto;
  width: 100%;
  align-items: center;
  text-align: center;
}

.App-header {
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; 
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: #333333;
  margin-top: 0;
}

.App-link {
  color: rgb(222, 190, 109);
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  padding-top: 70px;
  background-color: rgb(235, 235, 235);
  align-items: center;
  text-align: center;
   /* light grey */
}

h1, h2, h3, h4, h5, h6, p {
  color: rgb(50, 50, 50); /* dark grey */
}

/* Navbar styles */
.navbar {
  background-color: rgb(222, 190, 109); 
  color: rgb(50, 50, 50);
  /*rgb(222, 190, 109);*/
  /*rgb(222, 190, 109);*/
  border: 2px solid black;  
}

.navbarLogo{
  /* border-radius: 10px; */
  border: 2px solid black;
}

.nav-link {
  color: rgb(222, 190, 109); /* light grey */
}

/* Home page styles */
.App {
  text-align: center;
}

.App-header {
  background-color: whitesmoke; /* green */
  padding: 20px;  
}

.hero_image{
  padding-top: 50px;
  padding-bottom: 25px;
}

.carousel-caption {
  bottom: auto;
  top: 10%;
}

.carousel-caption h1 {
  font-family: 'Noto Serif Georgian', serif;  /* Using the Google Font */
  font-weight: 700;  /* As per the weight you selected */
  font-size: calc(20px + 4vmin);   /* Example size */
  color: rgb(222, 190, 109);      /* Example color */  
  /* -webkit-text-stroke: 1.5px black; */
  text-shadow: -1.5px -1.5px 0 #000, /* top left */
                 1.5px -1.5px 0 #000,  /* top right */
                 -1.5px 1.5px 0 #000,  /* bottom left */
                 1.5px 1.5px 0 #000;   /* bottom right */
  /* border:#333333; */
}

.carousel-caption p {
  font-family: 'YourPreferredFont', sans-serif;
  color: rgb(235, 235, 235);
  font-size: calc(10px + 2vmin); 
  /* -webkit-text-stroke: .50px black; */
  text-shadow: -1.5px -1.5px 0 #000, /* top left */
                 1.5px -1.5px 0 #000,  /* top right */
                 -1.5px 1.5px 0 #000,  /* bottom left */
                 1.5px 1.5px 0 #000;   /* bottom right */
}

.carousel img {
  /* opacity: 0.75;  0 is fully transparent, 1 is fully opaque */  
}

.carousel-item {
  height: 400px;  /* Adjust this value according to your needs */
  overflow: hidden;  /* This will hide parts of the image that exceed the container */
}

@media (max-width: 768px) {
  .carousel-item {
      height: 250px;
  }
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;  /* This ensures the image covers the space without distorting */
  object-position: center;  /* Adjust the positioning as needed */
}

/* Button styles */
.btn {
  background-color: rgb(222, 190, 109); /* blue */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.btn:hover {
  background-color: white;
  color: rgb(222, 190, 109);
}

/* Footer styles */
.footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.mobile-text {
  display: none;
}

.custom-btn {
  background-color: white !important;
  border-color: black !important;  /* Keeping the border color same as outline-secondary variant */
  color: black !important; /* Making text white for contrast */
}

.custom-btn:hover {
  background-color: rgb(222, 190, 109) !important; /* Changing background to lighter shade on hover */
  border-color: black !important;
  color: black !important;
}

.Nav-button{
  height:50px;
  width:125px;
  border: black;
  border-radius: 1px;
}


.Home_Page_Body{
  /* background-color: rgb(235, 235, 235);  */
  /* display: flex; */
  /* flex-direction: column; */
  font-size: calc(10px + 1.5vmin); 
  /* color: black; */
  margin-top: 0;
}

.ServiceArea_Home{
background-color: #2c3e50;
color: whitesmoke;
}
.ServicesArea_Title_Home{
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center; 
  font-size: calc(20px + 2vmin) !important;
}

.ServicesArea_Title_Home:hover{  
  color: rgb(222, 190, 109);
}

.ServiceArea_Body_Home{
  font-size: calc(10px + 1.5vmin); 
  color: whitesmoke !important;
}

.Services_Title_Home{
  /* background-color: rgb(235, 235, 235);  */
  display: flex;
  flex-direction: column;
  align-items: center; 
  font-size: calc(20px + 2vmin) !important;
  color: black;
  /* margin-top: 0; */
}

.Services_Title_Home:hover {
  color: rgb(222, 190, 109);
}

.Services_h2{
  color: black;
}
.Services_h2:hover {
  color: rgb(222, 190, 109);
}

.services-overview h2 {
  text-align: center;
  margin-top: 40px;
  font-size: calc(10px + 2vmin);
}

.services-overview ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
  font-size: calc(10px + 2vmin);
}

.ServiceCards{
  background-color: #2c3e50;
  /* rgb(222, 190, 109); */
  padding-top: 25px;
  border-radius: 5px;
}

.Services_Section{
  /* border: 2px solid #000; */
  /* background-color: whitesmoke; */
}

.Why_Clean_Title{
   /* background-color: rgb(235, 235, 235);   */
  display: flex;
  flex-direction: column;
  align-items: center; 
  font-size: calc(20px + 2vmin) !important;
  color: black;
}

.Why_Clean_Title:hover {
  color: rgb(222, 190, 109);
}
.Why_Clean{
  /* background-color: rgb(235, 235, 235);   */
 display: flex;
 flex-direction: column;
 /* align-items: center;  */
 font-size: calc(10px + 1.5vmin) !important;
 color: black;
}

.Why_Choose_Us_Title{
  display: flex;
  flex-direction: column;
  align-items: center; 
  font-size: calc(20px + 2vmin) !important;
  color: black;
}

.Why_Choose_Us_Title:hover {
  color: rgb(222, 190, 109);
}

.Why_Choose_Us{
  font-size: calc(10px + 1.5vmin)
}

.btn.HomePageButton {
  background-color: #2c3e50 !important;
  color: white !important;
  border-color: #333333 !important;
}

.btn.HomePageButton:hover {
  background-color: rgb(222, 190, 109) !important;
  color: white !important;
}

.sticky-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgb(222, 190, 109); /* or any other color */
  color: white;
  text-align: center;
  z-index: 1000; /* optional, to ensure the footer is on top of other elements */
}

.Footer_Header{ 
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Footer_Body{
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* This percentage gives a 16:9 aspect ratio */
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  /* This will apply to screens 768px and up (tablets, laptops, desktops) */
  .company-logo {
     width: 50%;  /*Adjust this to control the size of the image on larger screens */
  }
}

@media (min-width: 992px) { /* This is a typical breakpoint for desktops */
  .navbar {
    height: 125px; /* Adjust this to control the size of the navbar on larger screens */
  }
}

@media (min-width: 150px) { /* adjust breakpoint as needed */
  body {
    padding-top: 125px;
  }
}