.stickyFooterNumber{
    font-size: calc(10px + 1.5vmin); 
    background-color: #2c3e50 !important;
    color: white !important;
    border-color: #333333 !important;
}

.sticky-footer {
    /* Your existing styles... */
}

.social-media-links {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
}

.social-media-links a {
    color: #000; /* Or any color you prefer */
    text-decoration: none;
}

.social-media-links a:hover {
    color: #555; /* Change color on hover */
}
