.Services_Page_Header{
    text-align: center;
}

.services-page{
    font-size: calc(10px + 2vmin);
}

h1, h2, h3, h4, h5, h6, p {
    color: black; /* dark grey */
}

.Services_Page_Body{
    background-color: whitesmoke;
}

.services_hero_image{
    padding-top: 50px;
    padding-bottom: 50px;
}