.Contact_Page_Header{
    text-align: center;
}

.contact-page{
    font-size: calc(10px + 2vmin);
    background-color: whitesmoke;
}

h1, h2, h3, h4, h5, h6, p {
    color: black; /* dark grey */
}

