.Footer_Body1{
   
}

.Footer_Body2{    
    
}
.Footer_Main{   
    text-align: center;    
    font-size: calc(5px + 2vmin);   
    /* background-color:rgb(235, 235, 235) */
}
.Service_Area{       
    text-align: center;    
    font-size: calc(5px + 2vmin);    
}

.Footer_Image{
    width: 50%;
    height: 50%;
}

.Contact_Us{
    text-align: center;
}

.Contact_Area{

}

.Contact_Title{
    text-shadow: -1px 0 whitesmoke, 0 1px whitesmoke, 1px 0 whitesmoke, 0 -1px whitesmoke;
}

.Contact_Body{
    text-align: left;
}

.Services_Title{
    font-size: calc(10px + 2vmin);
    text-align: center;    
    /* text-shadow: -1px 0 whitesmoke, 0 1px whitesmoke, 1px 0 whitesmoke, 0 -1px whitesmoke; */
}

.Services{
    text-align: center;
}
.Services_Text{
    text-align: center;
}
.ServiceArea_Title{
    font-size: calc(10px + 2vmin);
    text-align: center;   
    /* text-shadow: -1px 0 whitesmoke, 0 1px whitesmoke, 1px 0 whitesmoke, 0 -1px whitesmoke; */
}

.ServiceArea_Text{
    text-align: center;    
}

.Contact_Title{
    font-size: calc(10px + 2vmin);
    text-align: center;    
}

.Contact_Body{
    font-size: calc(5px + 2vmin);
    text-align: center;   
}

.BlueSeparator{
    background-color: rgb(59, 95, 130);
    display: flex;
    flex-direction: column;
    align-items: center; 
    font-size: calc(10px + 2vmin);
    color: black;
    margin-top: 0;
  }

  .GoldenSeparator{
    background-color: rgb(222, 190, 109);
    display: flex;
    flex-direction: column;
    align-items: center; 
    font-size: calc(10px + 2vmin);
    color: black;
    margin-top: 0;
  }
